.uk-pc-happy-hour {
  width: 100%;
  margin: 0;
  background-color: white;

  .bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 20px 24px 20px;

    .title {
      font-weight: bold;
      height: 24px;
      font-size: 18px;
      line-height: 28px;
      display: flex;
      align-items: center;
      font-family: SF Pro Display;
    }

    .subTitle {
      height: 24px;
      line-height: 24px;
      font-family: SF Pro Display;
      font-size: 18px;
      font-weight: 400;
      color: #1a1a1a;
      display: flex;
      align-items: center;
      p {
        margin-right: 8px;
      }
    }
  }

  .products {
    display: flex;
    flex-direction: row;
    padding: 0px 26px 24px 26px;
    width: 1264px;
    gap: 28px;
  }
}
