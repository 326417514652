.uk-pc-flash-sales {
  width: 1264px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  border-radius: 8px;
  background-color: #00000005;

  .bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px 20px 24px;

    .title {
      font-weight: bold;
      height: 32px;
      font-size: 24px;
      line-height: 28px;
      display: flex;
      align-items: center;
    }

    .more {
      height: 28px;
      // line-height: 24px;
      font-size: 18px;
      font-weight: 400;
      color: #1a1a1a;
      // text-align: left;
      // background: url("./img/arrow-right.svg") no-repeat right;
      //padding-right: 24px;
      display: flex;
      align-items: center;

      p {
        margin-right: 8px;
      }

      span {
        margin: 0 1px;
      }

      .timeNum {
        display: inline-block;
        padding: 2px 0px;
        width: 32px;
        text-align: center;
        background-color: #1a1a1a;
        color: #ffffff;
        border-radius: 4px;
      }
    }

    .more:hover {

      p,
      .timeTitle {
        color: #990915;
      }

      .arrowRight {

        svg,
        path {
          stroke: #990915;
        }
      }

      .timeNum {
        background-color: #990915;
      }
    }

    .timeTitle {
      margin-right: 8px;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(26, 26, 26, 1);
    }
  }

  .products {
    display: flex;
    flex-direction: row;
    padding: 0px 26px 24px 26px;
    width: 1264px;
    overflow-x: scroll;
    gap: 28px;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}